.border_none .el-select[data-v-6718f7df] {
  width: 100% !important;
}
.font_cen[data-v-6718f7df] {
  text-align: center;
}
.el-input__inner[data-v-6718f7df] {
  width: 100% !important;
  background-color: none !important;
}
.el-radio + .el-radio[data-v-6718f7df] {
  margin-left: 7px;
}
.el-radio__label[data-v-6718f7df] {
  font-size: 12px !important;
}
.input_width input[data-v-6718f7df] {
  width: 60px !important;
  margin: 0 5px;
}
.width_big[data-v-6718f7df] {
  width: 20%;
}
.width_small[data-v-6718f7df] {
  width: 20%;
}
.width_medium[data-v-6718f7df] {
  width: 18%;
}
.width_ss[data-v-6718f7df] {
  width: 15%;
}
.width_xs[data-v-6718f7df] {
  width: 13%;
}
.gary_box[data-v-6718f7df] {
  background: #f6f6f6;
}
.blue_box[data-v-6718f7df] {
  background: #EBF5FF;
}
.el-date-editor.el-input[data-v-6718f7df], .el-date-editor.el-input__inner[data-v-6718f7df] {
  width: 180px !important;
}
.save_box[data-v-6718f7df] {
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
  padding-right: 15px;
}
.content[data-v-6718f7df] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-6718f7df] {
  line-height: 28px;
  font-size: 14px;
}
.content tr[data-v-6718f7df] {
  padding: 0;
  margin: 0;
}
.content td[data-v-6718f7df] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 28px;
  padding: 0 6px;
  color: #666;
  font-size: 12px;
}
.content input[data-v-6718f7df] {
  width: 100%;
  line-height: 28px;
  padding-left: 6px;
  font-size: 14px;
}
.left_k[data-v-6718f7df] {
  text-indent: 2em;
}
.center[data-v-6718f7df] {
  text-align: center;
}
.left[data-v-6718f7df] {
  text-align: left;
}
.right[data-v-6718f7df] {
  text-align: right;
}
.weight[data-v-6718f7df] {
  font-weight: 700;
}
.row_box[data-v-6718f7df] {
  border-bottom: 1px solid #eee;
}
.big_box4[data-v-6718f7df] {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: border-box;
}
.qsly[data-v-6718f7df] {
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.item_box[data-v-6718f7df] {
  position: relative;
  margin-top: 30px !important;
  border-top: 0 !important;
}
.item_box .add[data-v-6718f7df] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: -28px;
  top: 9px;
  font-size: 16px;
  color: #409EFF;
  cursor: pointer;
}
.item_box .add .svg-icon[data-v-6718f7df] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.close_item[data-v-6718f7df] {
  position: relative;
}
.close_item .close[data-v-6718f7df] {
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -24px;
  top: 0;
  font-size: 20px;
  color: #ff1818;
  cursor: pointer;
}
.close_item .close .svg-icon[data-v-6718f7df] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}